<template>
  <v-card
      color="white"
      elevation="5"
      outlined
      rounded
      width="380"
      height="500"
  >
    <v-card-title class="d-flex align-center justify-center">
      <v-card
          elevation="5"
          class="w-100 login-form-title-card"
          height="120"
          color="primary">
        <v-card-title class="w-100 text-h6 text-center d-flex align-center justify-center white--text">
          ورود به پنل مدیریت
        </v-card-title>
      </v-card>
    </v-card-title>

    <v-card-text class="pa-8">
      <v-text-field
          prepend-inner-icon="mdi-cellphone"
          type="tel"
          @keyup.enter="submit"
          v-model="phoneNumber"
          class="mb-8"
          outlined
          label="شماره موبایل"
          color="primary"
          placeholder="مثال : 09121234567"
      />

    </v-card-text>

    <v-card-actions

        class="d-flex align-center justify-center">
      <v-btn
          :disabled="!phoneNumber"
          rounded
          color="main"
          elevation="0"

          :loading="loading"
          @click="submit"
          x-large
      >
        <div class="white--text">
          ورود
        </div>
      </v-btn>
    </v-card-actions>

  </v-card>
</template>

<script>

export default {
  name: "LoginForm",

  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    editPhoneNumber: {
      type: String,
      default: '',
    }
  },

  mounted() {
    this.phoneNumber = this.editPhoneNumber;
  },

  data() {
    return {
      phoneNumber: ""
    }
  },

  methods: {
    submit() {
      this.$emit('onSubmit', this.phoneNumber);
    }
  }
}
</script>

<style scoped>
.form-shape {
  position: relative;
  left: 0px;
  right: 0px;
  top: -110px;
  display: flex;
  flex-direction: column;
  border-radius: 20px;
  transform-origin: right top;
  transform: rotate(-45deg);
  height: 150px;
  z-index: 20;
  width: 180px;
}

.form-shape:before {
  content: "";
  background: rgb(33, 150, 243);
  transform-origin: right top;
  top: 10px;
  right: 10px;
  opacity: 0.3;
  z-index: 1;
}

.form-shape:after {
  content: "";
  background: linear-gradient(0deg, rgb(32, 24, 71), rgb(16, 11, 36));
  z-index: 2;
}

.login-form-title-card {
  position: relative;
  top: -50px;
}
</style>